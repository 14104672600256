import { skipToken } from '@reduxjs/toolkit/dist/query'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { useGetClientTagGroupsQuery, type CampaignActivityItem, type OneTimeEmailCampaignType } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { useDestination } from '@sevenrooms/core/navigation'
import { differenceInCalendarDays } from '@sevenrooms/core/timepiece'
import { useDebounceEffect } from '@sevenrooms/core/ui-kit/hooks'
import { Grid, BaseSection, BorderedBox, VStack } from '@sevenrooms/core/ui-kit/layout'
import { campaignCenterMessages } from '@sevenrooms/marketing'
import { getPercentageOfTotal } from '@sevenrooms/marketing/utils'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { Box, Link, Skeleton, Stack } from '@sevenrooms/react-components'
import { routes } from '@sevenrooms/routes'
import { useGetEmailCampaignRecipientDetailsQuery, useGetEmailCampaignRecipientsByTagsQuery } from '../../hooks'
import { CampaignPerformanceClicksOpensTableFilters, type EmailClientTagOption } from '../CampaignPerformanceClicksOpensTableFilters'
import { CampaignPerformanceClientList } from '../CampaignPerformanceClientList'
import { CampaignPerformanceTagChart } from '../CampaignPerformanceTagChart'
import { CampaignProgressLine } from '../CampaignProgressLine'
import { SubjectLineAndPreview } from './SubjectLineAndPreview'

export function CampaignPerformanceOpens({
  emailCampaign,
  activity,
}: {
  emailCampaign: OneTimeEmailCampaignType
  activity: CampaignActivityItem
}) {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()
  const { params } = useDestination(routes.manager2.marketing.oneTimeEmailCenter.viewDeepDive)
  const [filterQuery, setFilterQuery] = useState<string>('')
  const [nameEmailFilterString, setNameEmailFilterString] = useState<string>('')
  const [filterTags, setFilterTags] = useState<EmailClientTagOption[]>([])
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  })
  const sentDate = emailCampaign.sentDateTimeLocal?.toJsDate()
  const daysCount = sentDate ? differenceInCalendarDays(new Date(), sentDate) : 0
  const opensRate = activity.recipients > 0 ? Math.ceil((activity.opens / activity.recipients) * 100) : 0

  useDebounceEffect(
    () => {
      setNameEmailFilterString(filterQuery)
    },
    500,
    [filterQuery]
  )

  const { data: emailCampaignRecipientData, isFetching: isEmailCampaignRecipientDetailsLoading } = useGetEmailCampaignRecipientDetailsQuery(
    params.campaignId && venueId && paginationModel
      ? {
          venueId,
          id: params.campaignId,
          eventType: 'OPENED',
          pageNumber: paginationModel.page + 1,
          pageSize: paginationModel.pageSize,
          nameEmailFilterString,
          tagFilterString: filterTags?.map(tag => tag.tag_id),
        }
      : skipToken
  )

  const { data: emailCampaignRecipientsByTagData, isFetching: isEmailCampaignRecipientsByTagLoading } =
    useGetEmailCampaignRecipientsByTagsQuery(
      params.campaignId && venueId
        ? {
            venueId,
            id: params.campaignId,
            eventType: 'OPENED',
            nameEmailFilterString,
            tagFilterString: filterTags?.map(tag => tag.tag_id),
          }
        : skipToken
    )

  const { data: tagGroups, isLoading: isTagGroupsLoading } = useGetClientTagGroupsQuery({
    venueKey: venueId,
    includeRebuildState: false,
  })

  const recipients = emailCampaignRecipientData?.data?.clients ?? []

  const tags = useMemo(
    () =>
      _.flatten(
        (tagGroups ?? []).map(tagGroup =>
          _.map(_.isEmpty(tagGroup.tagNameDisplays) ? tagGroup.tags : tagGroup.tagNameDisplays, (tag, key) => ({
            group: tagGroup.nameDisplay,
            color_hex: tagGroup.colorHex,
            tag_name_display: tag as string,
            tag_id: `${tagGroup.privacy}##${tagGroup.id}##${tagGroup.name}##${_.isEmpty(tagGroup.tagNameDisplays) ? tag : key}`,
            is_autotag: tagGroup.isAutotag,
            tag_name: _.isEmpty(tagGroup.tagNameDisplays) ? tag : key,
          })).filter(tag => !_.includes(['All Guests', 'Group All Guests', 'Subscribed Guests', 'Group Marketing Opt-Ins'], tag.tag_name))
        )
      ),
    [tagGroups]
  )

  const campaignPerformanceOpensTitle = useMemo(() => {
    if (opensRate >= 40) {
      return formatMessage(campaignCenterMessages.campaignPerformanceOpensExcellentTitle)
    } else if (opensRate >= 15) {
      return formatMessage(campaignCenterMessages.campaignPerformanceOpensGoodTitle)
    }

    return formatMessage(campaignCenterMessages.campaignPerformanceOpensFairTitle)
  }, [opensRate, formatMessage])

  const campaignPerformanceOpensDescription = useMemo(() => {
    if (opensRate >= 40) {
      return formatMessage(campaignCenterMessages.campaignPerformanceOpensExcellentDescription)
    } else if (opensRate >= 15) {
      return formatMessage(campaignCenterMessages.campaignPerformanceOpensGoodDescription)
    }

    return formatMessage(campaignCenterMessages.campaignPerformanceOpensFairDescription, {
      link: (chunks: string) => (
        <Link data-test="fair-opens-description-link" href={chunks} target="_blank">
          {chunks}
        </Link>
      ),
    })
  }, [opensRate, formatMessage])

  return (
    <VStack spacing="lm" mb="lm">
      <Grid gridTemplateColumns="205px repeat(2, minmax(0px, 1fr))" gap="lm" columnGap="lm">
        <VStack spacing="lm" justifyContent="space-between">
          <BaseSection
            collapsed
            padding="m"
            title={activity.opens}
            subCaption={formatMessage(campaignCenterMessages.campaignPerformanceUniqueOpens)}
          />
          <BaseSection
            collapsed
            padding="m"
            title={getPercentageOfTotal(activity.opens, activity.recipients)}
            subCaption={formatMessage(campaignCenterMessages.campaignPerformanceOpens)}
          />
        </VStack>

        <BaseSection maxWidth="none" title={campaignPerformanceOpensTitle} subCaption={campaignPerformanceOpensDescription}>
          <CampaignProgressLine performance={opensRate} standard={40} />
        </BaseSection>
        <SubjectLineAndPreview emailCampaign={emailCampaign} />
      </Grid>

      <BaseSection
        maxWidth="none"
        title={formatMessage(campaignCenterMessages.campaignPerformanceOpensByClientTagTitle)}
        description={formatMessage(campaignCenterMessages.campaignPerformanceOpensByClientTagDescription)}
      >
        <BorderedBox backgroundColor="margin" height="273px" m="lm" />
      </BaseSection>

      <BaseSection
        maxWidth="none"
        title={formatMessage(campaignCenterMessages.campaignPerformanceOpensByTagsTitle, {
          value: emailCampaignRecipientData?.data?.clientsTotal ?? 0,
        })}
        description={formatMessage(campaignCenterMessages.campaignPerformanceOpensByTagsDescription, { value: daysCount })}
        actions={
          <CampaignPerformanceClicksOpensTableFilters
            isTagGroupsLoading={isTagGroupsLoading}
            tagOptions={tags}
            filterTags={filterTags}
            onFilterTagsChange={setFilterTags}
            filterQuery={filterQuery}
            onFilterQueryChange={setFilterQuery}
          />
        }
      >
        <Stack px={6} mb={6} mt={6} flexDirection="column" gap={6}>
          {isEmailCampaignRecipientsByTagLoading ? (
            <Skeleton
              variant="rounded"
              sx={{
                height: 74,
                width: '100%',
              }}
            />
          ) : (
            emailCampaignRecipientsByTagData?.data?.tagStatistics &&
            !_.isEmpty(emailCampaignRecipientsByTagData?.data?.tagStatistics) && (
              <Box px="10%" maxHeight="520px" overflow="auto" width="100%" sx={{ backgroundColor: '#F7F7F7' }}>
                <CampaignPerformanceTagChart data={emailCampaignRecipientsByTagData?.data?.tagStatistics} />
              </Box>
            )
          )}

          <CampaignPerformanceClientList
            isLoading={isEmailCampaignRecipientDetailsLoading}
            rowCount={emailCampaignRecipientData?.data?.clientsTotal ?? 0}
            data={recipients}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        </Stack>
      </BaseSection>
    </VStack>
  )
}
