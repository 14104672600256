import { defineMessages } from '@sevenrooms/core/locales'

export const ShriftReportingPeriodSettingsMessages = defineMessages({
  shiftReportingPeriods: {
    id: 'settings.general.shriftReportingPeriods.shiftReportingPeriods',
    defaultMessage: 'Shift Reporting Periods',
  },
  generalSettings: {
    id: 'settings.general.shriftReportingPeriods.generalSettings',
    defaultMessage: 'General Settings',
  },
  reportingPeriods: {
    id: 'settings.general.shriftReportingPeriods.reportingPeriods',
    defaultMessage: 'Reporting Periods',
  },
  reportingPeriodDefault: {
    id: 'settings.general.shriftReportingPeriods.reportingPeriodDefault',
    defaultMessage: 'Reporting period template default',
  },
  addReportingPeriod: {
    id: 'settings.general.shriftReportingPeriods.addReportingPeriod',
    defaultMessage: 'Add Shift Period Group',
  },
  enterReportingPeriodsName: {
    id: 'settings.general.shriftReportingPeriods.enterReportingPeriodsName',
    defaultMessage: 'Reporting Periods Group Name',
  },
  defaultReportingPeriod: {
    id: 'settings.general.shriftReportingPeriods.defaultReportingPeriod',
    defaultMessage: 'Default Reporting Period',
  },
  breakfast: {
    id: 'settings.general.shriftReportingPeriods.breakfast',
    defaultMessage: 'Breakfast',
  },
  brunch: {
    id: 'settings.general.shriftReportingPeriods.brunch',
    defaultMessage: 'Brunch',
  },
  lunch: {
    id: 'settings.general.shriftReportingPeriods.lunch',
    defaultMessage: 'Lunch',
  },
  dinner: {
    id: 'settings.general.shriftReportingPeriods.dinner',
    defaultMessage: 'Dinner',
  },
  nightTime: {
    id: 'settings.general.shriftReportingPeriods.nightTime',
    defaultMessage: 'Night',
  },
  shiftReportingTitle: {
    id: 'settings.general.shriftReportingPeriods.shiftReportingTitle',
    defaultMessage: 'Shift Reporting Periods',
  },
  shiftReportingDescription: {
    id: 'settings.general.shriftReportingPeriods.shiftReportingDescription',
    defaultMessage:
      "Define custom Shift Reporting Periods to match your venue's schedule for clear, consolidated reporting and better insights. After adding a Shift Reporting Period, assign it to the corresponding Shifts.",
  },
  success: {
    id: 'settings.general.shriftReportingPeriods.success',
    defaultMessage: 'Changes have been saved successfully.',
  },
  error: {
    id: 'settings.general.shriftReportingPeriods.error',
    defaultMessage: 'An error occurred while saving changes.',
  },
  deleteModalAriaLabel: {
    id: 'settings.general.shriftReportingPeriods.deleteModalAriaLabel',
    defaultMessage: 'Delete Shift Confirmation Modal',
  },
  deleteTitle: {
    id: 'settings.general.shriftReportingPeriods.deleteTitle',
    defaultMessage: 'Are you sure you want to delete "{name}"?',
  },
  deleteDescription: {
    id: 'settings.general.deleteDescription.deleteTitle',
    defaultMessage: 'Currently used by the following shifts:',
  },
  shiftReportingPeriodsLessThanTwo: {
    id: 'settings.general.shriftReportingPeriods.shiftReportingPeriodsLessThanTwo',
    defaultMessage: 'At least 2 shift reporting periods are required.',
  },
  atLeastOneReportingPeriodIsRequired: {
    id: 'settings.general.shriftReportingPeriods.minimumShiftReportingPeriods',
    defaultMessage: 'At least 1 reporting periods group is required.',
  },
  reportingPeriodGroupNameLabel: {
    id: 'settings.general.shriftReportingPeriods.reportingPeriodNameLabel',
    defaultMessage: 'Reporting Periods Group Name',
  },
})
